import { Fragment, useContext } from 'react';
import classnames from 'classnames';

import { MiniProgressBar } from '../miniprogressbar/MiniProgressBar';
import { FullFrameControlButton } from '../buttons/controls/fullframe/FullFrameControlButton';
import { SeekMinusControlButton } from '../buttons/controls/seekminus/SeekMinusControlButton';
import { SeekPlusControlButton } from '../buttons/controls/seekplus/SeekPlusControlButton';
import { PlayPauseControlButton } from '../buttons/controls/playpause/PlayPauseControlButton';

import { MinimalPlayerContext } from '../../context';
import { useAriaLabels } from '../../hooks';

import styles from './WebMiniBottomControls.css';
import tvStyles from './TvMiniBottomControls.css';

interface IDefaultProps {
  isFullFrame?: boolean;
  shouldShowProgressBar?: boolean;
  shouldShowControlButtons?: boolean;
  isTv?: boolean;
}

export interface IProps extends IDefaultProps {
  onFullFrame: () => void;
  onPlay?: () => void | undefined;
  onPause?: () => void | undefined;
  onSeekPlus?: () => void | undefined;
  onSeekMinus?: () => void | undefined;
  onProgressBarSeek?: () => void | undefined;
}

const MiniBottomControls = ({
  isTv = false,
  isFullFrame = true,
  shouldShowProgressBar = true,
  shouldShowControlButtons = true,
  onFullFrame,
  onPlay,
  onPause,
  onSeekPlus,
  onSeekMinus,
  onProgressBarSeek,
}: IProps): JSX.Element | null => {
  const { minimalPlayer } = useContext(MinimalPlayerContext);
  const ariaLabels = useAriaLabels(minimalPlayer, [
    'FullScreenExit',
    'FullScreen',
  ]);

  if (!minimalPlayer) {
    return null;
  }

  return (
    <Fragment>
      {isTv && (
        <div
          className={tvStyles.contentBackground}
          data-testid="TvMiniBottomControlsBackground"
        />
      )}
      <div
        className={classnames(
          isTv ? tvStyles.miniBottomControls : styles.miniBottomControls,
        )}
      >
        <div
          className={
            isTv ? tvStyles.controlsWrapBottom : styles.controlsWrapBottom
          }
        >
          {!isTv && shouldShowControlButtons && (
            <div
              className={styles.buttonsWrapper}
              data-testid="MiniBottomLeftControls"
            >
              <SeekMinusControlButton onClick={onSeekMinus} />
              <PlayPauseControlButton onPlay={onPlay} onPause={onPause} />
              <SeekPlusControlButton onClick={onSeekPlus} />
            </div>
          )}

          {shouldShowProgressBar && (
            <MiniProgressBar onSeek={onProgressBarSeek} isTv={isTv} />
          )}

          {!isTv && (
            <div
              className={classnames({
                [styles.rightAlignedControl]:
                  !shouldShowProgressBar || !shouldShowControlButtons,
              })}
            >
              <FullFrameControlButton
                isFullFrame={isFullFrame}
                onClick={onFullFrame}
                ariaLabel={
                  isFullFrame
                    ? ariaLabels.FullScreenExit
                    : ariaLabels.FullScreen
                }
              />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export { MiniBottomControls };

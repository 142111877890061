import { CSSProperties, forwardRef } from 'react';
import classnames from 'classnames';

import styles from './Slider.css';
import tvStyles from './TvSlider.css';

interface IDefaultProps {
  minValue?: number;
  maxValue?: number;
  step?: number;
  onDrag?: (evt: React.FormEvent<HTMLInputElement>) => void;
}

export interface IProps extends IDefaultProps {
  isTv: boolean;
  value: number;
  ariaLabel: string;
  onMouseUp?: () => void;
  onMouseDown?: () => void;
  ref?: React.MutableRefObject<HTMLInputElement | null> | null;
  customStyle?: CSSProperties;
}

/**
 *
 * @param arg0 Slider props
 * @param arg0.isTv tv or web slider (for default style)
 * @param arg0.value current value of the slider
 * @param arg0.minValue minimum value of the slider
 * @param arg0.maxValue maximum value of the slider
 * @param arg0.onMouseDown callback executed when pressing the mouse
 * @param arg0.onMouseUp callback executed when releasing the mouse
 * @param arg0.onDrag callback executed when dragging the handler
 * @param arg0.ref ref to attach to the slider
 * @param arg0.customStyle custom style to apply to the slider
 * @param arg0.step input range step
 * @returns Slider component and the ref attached to it
 */
const Slider = forwardRef<HTMLInputElement, IProps>(
  (
    {
      isTv,
      minValue = 0,
      maxValue = 100,
      value,
      step = 0.1,
      onDrag = (): void => {},
      onMouseDown,
      onMouseUp,
      customStyle,
      ariaLabel,
    }: IProps,
    ref,
  ): JSX.Element | null => {
    return (
      <input
        ref={ref}
        step={step}
        type="range"
        min={minValue}
        max={maxValue}
        value={value}
        aria-label={ariaLabel}
        aria-valuemin={minValue}
        aria-valuemax={maxValue}
        aria-valuenow={value}
        onInput={onDrag}
        onMouseUp={onMouseUp}
        onTouchEnd={onMouseUp}
        onTouchStart={onMouseDown}
        onMouseDown={onMouseDown}
        style={customStyle}
        className={classnames(isTv ? tvStyles.slider : styles.slider)}
      />
    );
  },
);

export { Slider };
